/* eslint-disable id-length */

/**
 * Module dependencies.
 */

import { ButtonProps } from 'src/components/core/buttons/card-button';
import { SubscriptionCard as SubscriptionCardType } from 'src/api/entities/sections/cards/types';
import { SubscriptionContentComponent, SubscriptionContentProps } from 'src/components/sections/subscription';
import { desktopHeights, mobileAspectRatios, mobileSizes } from './content';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useMemo } from 'react';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `SubscriptionCard` constant.
 */

export const SubscriptionCard = (
  props: Omit<SubscriptionCardType & { shrinkFactor?: number }, 'cta'> & {
    cta?: Omit<ButtonProps, 'children'> & { label?: string };
  }
) => {
  const {
    content,
    layout: { colSpan = '1', height = 'S' },
    shrinkFactor = 1,
    ...rest
  } = props;

  const { t } = useTranslate();
  const isMobile = !useBreakpoint('ms');

  const style = useMemo(() => {
    if (isMobile) {
      const mobileSize = mobileSizes[height][colSpan];

      return { aspectRatio: mobileAspectRatios[mobileSize] };
    }

    if (height === 'full') {
      return { height: desktopHeights[height] };
    }

    return { '--subscription-grid-padding': '32px', height: shrinkFactor * (desktopHeights[height] as number) };
  }, [colSpan, height, isMobile, shrinkFactor]);

  return (
    <SubscriptionContentComponent
      {...(rest as Omit<SubscriptionContentProps, 'lead' | 'form' | 'rules'>)}
      {...content}
      action={'card_subscription'}
      anchorId={props.anchorId}
      id={props.id as string}
      layout={colSpan === '1' || colSpan === '1.5' ? 'card' : 'default'}
      rules={{
        required: t('subscription.form.email.rule.required'),
        validEmail: t('subscription.form.email.rule.validEmail')
      }}
      style={style}
    />
  );
};

/**
 * Module dependencies.
 */

import { BackLink } from 'src/components/core/links/back-link';
import { LegalDetailsSectionFragment } from 'src/api/entities/sections/legal-details/types';
import { MobileScrollSpyWrapper } from 'src/components/core/scroll-spy/mobile-scroll-spy';
import { RichText } from 'src/components/rich-text';
import { ScrollSpy } from 'src/components/core/scroll-spy';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { linkAnimatedStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useScrollSpy } from 'src/hooks/use-scroll-spy';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = LegalDetailsSectionFragment & {
  backButtonLabel: string;
  backButtonUrl: string;
};

/**
 * 'Grid' styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas: 'header' 'content' 'scrollspy';
  padding-top: var(--top-banner-height);

  &[data-index='false'] {
    grid-template-columns: 1fr;
  }

  ${media.min.lg`
    grid-template-areas: 'header scrollspy' 'content scrollspy';
    grid-template-columns: 1fr min(100% - var(--gutter-cards), 384px);
    grid-column-gap: 96px;
  `}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  display: grid;
  grid-row-gap: 32px;
  padding-bottom: 64px;

  ${media.max.lg`
    grid-row-gap: 24px;
    padding-bottom: 48px;
  `}
`;

/**
 * 'TitleWrapper' styled component.
 */

const HeaderTitle = styled.div`
  display: grid;
  grid-row-gap: 12px;
`;

/**
 * `StyledScrollSpy` styled component.
 */

const StyledScrollSpy = styled(ScrollSpy)`
  grid-area: scrollspy;

  ${media.max.lg`
    grid-area: mobilespy;
    padding: 0 0 16px;
  `}

  ${media.min.lg`
    height: max-content;
    margin-right: var(--gutter-cards);
    max-height: calc(100vh - (var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--bottom-banner-height) + var(--gutter-navbar-y) * 3) - 32px);
    overflow-y: auto;
    position: sticky;
    top: calc((var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3) + 50px);
    transition: 0.25s ease-out;
    transition-property: background-color, color, max-height;
    width: calc(100% - var(--gutter-cards));
  `}
`;

/**
 * 'StyledRichText' styled component.
 */

const StyledRichText = styled(RichText)`
  font-size: 18px;
  font-weight: 400;
  grid-area: content;
  letter-spacing: -0.01em;
  line-height: 23.4px;

  a {
    ${linkAnimatedStyles}

    color: var(--color-primary);
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 26px;
    margin-bottom: 24px;
    padding-bottom: 0;
    padding-top: 24px;
    scroll-margin-top: calc(
      var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3
    );

    ${media.min.lg`
      font-size: 24px;
      letter-spacing: -0.02em;
      line-height: 31.2px;
      padding-top: 32px;
    `}
  }

  p {
    margin-bottom: 24px;

    ${media.max.lg`
      font-size: 18px;
      line-height: 23.4px;

      br {
        content: '';
      }

      br::after {
        content: ', ';
        display: inline;
      }
    `}
  }

  colgroup {
    ${media.max.lg`
      display:none;
    `}
  }

  td {
    font-size: 18px;
    line-height: 23.4px;

    ${media.max.lg`
      &[has-nbsp="true"] {
        display: none;
      }
    `}

    ${media.min.lg`
      padding: 24px 32px 24px 0;
    `}
  }

  table {
    border-collapse: collapse;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    overflow-x: scroll;
    table-layout: auto;

    th {
      color: var(--color-neutral50);
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      padding: 24px 0;
      text-align: left;
      vertical-align: top;
    }

    thead {
      ${media.min.lg`
        display: table-header-group;
      `}
    }

    tbody {
      ${media.min.lg`
        border-top: 1px solid rgb(235, 236, 245);
      `}
    }

    tr {
      text-align: left;
      vertical-align: top;

      &:not(.next-has-nbsp) {
        border-bottom: 1px solid #ebecf5;
      }

      td:nth-child(1) {
        color: var(--color-neutral90);
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;

        ${media.max.lg`
          margin-bottom: 8px;
        `}
      }

      :last-child {
        border-bottom: none;
      }

      td > p {
        color: var(--color-neutral70);
        padding-bottom: 8px;

        ${media.min.lg`
          padding-bottom: 0;
        `}
      }
    }

    ${media.max.lg`
      table, thead, tbody, th, td, tr {
        display: block;
      }

      thead tr {
        display: none;
      }

      thead + tbody td::before {
        color: var(--color-neutral50);
        content: attr(data-label);
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        padding: 8px 0;
        width: 100%;
      }
    `}
  }

  ol {
    counter-reset: index;
    display: grid;
    padding: 0;

    > li {
      display: grid;
      grid-column-gap: 8px;
      grid-template-columns: 32px 1fr;
      padding: 0;

      ${media.min.lg`
        grid-template-columns: 48px 1fr;
      `}

      :not(:last-child) {
        margin-bottom: 32px;
      }

      p:last-child {
        margin-bottom: 0;
      }

      ::before {
        content: counters(index, '.') '. ';
        counter-increment: index;
        font-size: 18px;
        letter-spacing: -0.01em;
        line-height: 23.4px;

        ${media.min.lg`
          font-size: 20px;
          letter-spacing: -0.02em;
          line-height: 26px;
        `}
      }

      &:has(> h2) {
        ::before {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.01em;
          line-height: 26px;
          padding-top: 24px;

          ${media.min.lg`
            font-size: 24px;
            letter-spacing: -0.02em;
            line-height: 31.2px;
            padding-top: 32px;
          `}
        }
      }

      > ol {
        grid-column: span 2;

        li {
          margin-bottom: 0;
        }

        > ol {
          grid-column: 2;
        }
      }

      > p:not(:first-child) {
        grid-column: span 2;

        ${media.min.lg`
          grid-column: 2;
        `}
      }

      > ul {
        grid-column: 2;
        list-style: none;
        margin-bottom: 32px;
        padding: 0;

        :last-child {
          margin-bottom: 0;
        }

        > li {
          padding-left: 28px;
          position: relative;

          ::before {
            content: url('/images/arrow-right.svg');
            left: 0;
            position: absolute;
            top: 2px;
          }

          :not(:last-child) {
            margin-bottom: 16px;
          }

          ${media.max.lg`
            font-size: 18px;
            line-height: 23.4px;
          `}
        }
      }
    }
  }

  ${media.min.lg`
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 26px;
  `}
`;

/**
 * Export `LegalDetailsSection` component.
 */

export function LegalDetailsSection(props: Props) {
  const { backButtonLabel, backButtonUrl, content, navigationList, showSideNavigation, theme, title, updatedAt } =
    props;

  const isDesktop = useBreakpoint('lg');
  const { activeIndex, contentRef } = useScrollSpy<HTMLHeadingElement, HTMLDivElement>('h2');
  const hasNavigation = navigationList && navigationList.length > 0;

  const renderScrollSpy = () =>
    hasNavigation && (
      <StyledScrollSpy data-theme={theme} isNumeric selfIndex={activeIndex} siblingNodes={navigationList} />
    );

  return (
    <Section>
      <Grid data-index={showSideNavigation}>
        <Header>
          {backButtonUrl && backButtonLabel && (
            <BackLink href={backButtonUrl} iconSize={isDesktop ? 24 : 20} variant={isDesktop ? 'label1' : 'label2'}>
              {backButtonLabel}
            </BackLink>
          )}

          <HeaderTitle>
            <Text as={'h1'} variant={'heading2'}>
              {title}
            </Text>

            <Text as={'h2'} fontWeight={400} variant={'subtitle2'}>
              {updatedAt}
            </Text>
          </HeaderTitle>
        </Header>

        <StyledRichText ref={contentRef}>{content}</StyledRichText>

        {isDesktop && showSideNavigation && renderScrollSpy()}
      </Grid>

      {hasNavigation && !isDesktop && (
        <MobileScrollSpyWrapper title={title}>{renderScrollSpy()}</MobileScrollSpyWrapper>
      )}
    </Section>
  );
}

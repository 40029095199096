/**
 * Module dependencies.
 */

import { useEffect, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'framer-motion';

/**
 * Export `useScrollSpy` hook.
 */

export function useScrollSpy<T extends HTMLElement, Ref extends HTMLElement>(selector: string) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const contentRef = useRef<Ref>(null);
  const prevScrollY = useRef<number>(0);
  const selectorRef = useRef<T[]>([]);
  const [visibleRefs, setVisibleRefs] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const index = Object.values(visibleRefs).findIndex(key => key);

    // Determine scroll direction
    const currentScrollY = window.scrollY;
    const scrollDirection = currentScrollY > prevScrollY.current ? 'down' : 'up';
    prevScrollY.current = currentScrollY;

    // Set the active index based on the scroll direction
    setActiveIndex(currActiveIndex => {
      if (scrollDirection === 'up') {
        if (index > -1) {
          return index;
        }

        return currActiveIndex - 1;
      }

      if (index > -1) {
        return index;
      }

      return currActiveIndex;
    });
  }, [visibleRefs]);

  useIsomorphicLayoutEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const elements = contentRef.current.querySelectorAll(selector);

    selectorRef.current = Array.from(elements) as NonNullable<T[]>;

    const observer = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          const index = selectorRef.current.indexOf(entry.target as T);

          if (entry.isIntersecting) {
            setVisibleRefs(prevState => ({ ...prevState, [index]: true }));
          } else {
            setVisibleRefs(prevState => ({ ...prevState, [index]: false }));
          }
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      }
    );

    for (const elem of selectorRef.current) {
      if (elem) {
        observer.observe(elem);
      }
    }

    return () => {
      for (const elem of selectorRef.current) {
        if (elem) {
          observer.unobserve(elem);
        }
      }
    };
  }, []);

  return { activeIndex, contentRef };
}

/**
 * Export `stripStyles` util.
 */

export const stripStyles = (text?: string) => {
  if (!text) {
    return text;
  }

  const keepStylesTags = ['col', 'th', 'td'];

  // Regular expression to match any html tag except those in the keepStylesTags
  const pattern = new RegExp(`<((?!${keepStylesTags.join('|')})\\w+)([^>]*?)\\sstyle="[^"]*"(.*?)>`, 'gi');

  // Replace matching tags with the style attribute removed
  return text.replace(pattern, '<$1$2$3>');
};

/**
 * Export `stripTags` util.
 */

export const stripTags = (html?: string) => {
  return html?.replace(/<[^>]*>?/gm, '');
};

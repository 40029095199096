/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Checkbox } from 'src/components/core/forms/checkbox';
import { ComponentPropsWithoutRef, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  FormFieldInput,
  FormFieldSelectCountries,
  FormFieldSelectCountriesStates,
  FormFieldSelectCustom,
  FormFragment
} from 'src/api/entities/form/types';

import { FormProvider, useForm } from 'react-hook-form';
import { Input } from './fields/input';
import { SelectCountries } from './fields/select-country';
import { SelectCustom } from './fields/select-custom';
import { SelectStates } from './fields/select-state';
import { Spinner } from 'src/components/core/spinner';
import { toast } from 'src/context/toast';
import { useMutation } from '@tanstack/react-query';
import { useSettings } from 'src/context/settings';
import { useTranslate } from 'src/context/i18n';
import arrowRight from 'src/assets/svgs/24/arrow-right.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ComponentPropsWithoutRef<'form'> & FormFragment;

/**
 * `useFormMutation` hook.
 */

function useFormMutation({
  handleReset,
  integration,
  urlForward
}: Pick<FormFragment, 'integration' | 'urlForward'> & { handleReset: () => void }) {
  const { t } = useTranslate();
  const { queryCaptureParametersWhitelist } = useSettings();

  const mutation = useMutation({
    mutationFn: async (data: Record<string, any>) => {
      if (integration === 'url_forward') {
        const urlWithParams = new URL(urlForward.url);
        const allParams = {
          ...Object.fromEntries((urlForward.extraParams ?? [])?.map(({ name, value }) => [name, value])),
          ...omit(data, 'terms')
        } as Record<string, string>;

        if (queryCaptureParametersWhitelist?.length) {
          const searchParams = new URLSearchParams(window.location.search);

          for (const key of queryCaptureParametersWhitelist) {
            const value = searchParams.get(key);

            if (value) {
              urlWithParams.searchParams.append(key, value);
            }
          }
        }

        for (const [key, value] of Object.entries(allParams)) {
          if (value) {
            urlWithParams.searchParams.append(key, value);
          }
        }

        window.open(urlWithParams.toString(), urlForward.openInNewTab ? '_blank' : '_self');

        return await Promise.resolve();
      }
    },
    onError: () => {
      toast.error(t('forms.messages.submit.error'));
    },
    onSuccess: () => {
      toast.success(t('forms.messages.submit.success'));
      handleReset();
    }
  });

  return mutation;
}

/**
 * `FormWrapper` styled component.
 */

const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  scroll-margin-top: calc(var(--padding-top) + 32px);

  > div {
    flex-basis: calc(var(--display-template, 100) * 1% - 16px);
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

/**
 * Export `Form` component.
 */

export const Form = ({ fields, id, integration, submitLabel, terms, urlForward, ...rest }: Props) => {
  const { t } = useTranslate();
  const defaultValues = useMemo(
    () =>
      fields.reduce(
        (acc, field) => {
          if (field.type === 'input') {
            acc[field.input.name] = field.defaultValue ?? null;
          }

          if (field.type === 'select' && field.select.type === 'custom') {
            acc[field.select.name] = field.defaultValue ?? null;
          }

          if (field.type === 'select' && field.select.type === 'countries') {
            acc[field.select.name] = null;
          }

          if (field.type === 'select' && field.select.type === 'countries_with_states') {
            acc[field.select.name] = null;
            acc[field.select.stateField.name] = null;
          }

          return acc;
        },
        { terms: false } as Record<string, any>
      ),
    [fields]
  );

  const formRef = useRef<HTMLFormElement>(null!);
  const form = useForm({ defaultValues, mode: 'onBlur' });
  const { formState, handleSubmit, register, reset, trigger } = form;

  const handleReset = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const { isPending, mutate } = useFormMutation({ handleReset, integration, urlForward });

  const onSubmit = useMemo(
    () =>
      handleSubmit(data => {
        mutate(data);
      }),
    [handleSubmit, mutate]
  );

  const errors = formState.errors as Record<string, { message?: string }>;

  useEffect(() => {
    const onHashChange = () => {
      if (window.location.hash.slice(1) === id) {
        formRef.current.querySelector('input')?.focus();
      }
    };

    onHashChange();
    window.addEventListener('hashchange', onHashChange);

    return () => window.removeEventListener('hashchange', onHashChange);
  }, [id]);

  return (
    <FormProvider {...form}>
      <FormWrapper
        aria-disabled={isPending}
        data-tight-labels
        id={id}
        noValidate
        onSubmit={onSubmit}
        ref={formRef}
        {...rest}
      >
        {fields.map(field => (
          <Fragment key={field.id}>
            {field.type === 'input' && <Input {...(field as FormFieldInput)} />}

            {field.type === 'select' && field.select.type === 'custom' && (
              <SelectCustom {...(field as FormFieldSelectCustom)} />
            )}

            {field.type === 'select' && ['countries', 'countries_with_states'].includes(field.select.type) && (
              <SelectCountries {...(field as FormFieldSelectCountries | FormFieldSelectCountriesStates)} />
            )}

            {field.type === 'select' && field.select.type === 'countries_with_states' && (
              <SelectStates {...(field as FormFieldSelectCountriesStates)} />
            )}
          </Fragment>
        ))}

        <Checkbox
          error={errors.terms?.message}
          id={'terms'}
          label={terms}
          labelHasHtml
          {...register('terms', {
            onChange: () => trigger('terms'),
            required: t('forms.fields.validation.terms')
          })}
        />

        <Button
          disabled={isPending}
          icon={arrowRight}
          style={{ marginLeft: 'auto' }}
          type={'submit'}
          variant={'secondary'}
        >
          <Spinner active={isPending} size={16} />

          {submitLabel}
        </Button>
      </FormWrapper>
    </FormProvider>
  );
};

/**
 * Module dependencies.
 */

import { Asset } from 'src/api/entities/asset/types';
import { Breakpoint, breakpoints } from 'src/styles/breakpoints';
import { ComponentPropsWithRef, forwardRef, useEffect, useRef } from 'react';
import { getFileType } from 'src/core/utils/files';
import { mergeRefs } from 'src/core/utils/refs';
import { useBattery } from 'src/context/battery';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDeviceSource } from 'src/hooks/use-device-source';
import { useInView } from 'framer-motion';
import { useSupportsHevcAlpha } from 'src/context/hevc-alpha';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ComponentPropsWithRef<'video'> & {
  assets: {
    desktop?: Asset;
    mobile?: Asset;
  };
  assetsWebkit: {
    desktop?: Asset;
    mobile?: Asset;
  };
  mobileBreakpoint: Breakpoint;
  thumbnails: {
    desktop?: Asset;
    mobile?: Asset;
  };
};

/**
 * `FillVideo` styled component.
 */

export const FillVideo = styled.video`
  height: 100%;
  inset: 0;
  object-fit: contain;
  position: absolute;
  width: 100%;
`;

/**
 * `VideoCommon` component.
 */

const VideoCommon = forwardRef<HTMLVideoElement, Props>(
  ({ assets, assetsWebkit, mobileBreakpoint, thumbnails, ...rest }, videoRef) => {
    const supportsHevcAlpha = useSupportsHevcAlpha();
    const { isLow } = useBattery();
    const { desktop: assetDesktop, mobile: assetMobile } =
      supportsHevcAlpha && (assetsWebkit.desktop || assetsWebkit.mobile) ? assetsWebkit : assets;

    const layout = useDeviceSource([assetDesktop?.layout, assetMobile?.layout], mobileBreakpoint);
    const poster = useDeviceSource([thumbnails.desktop, thumbnails.mobile], mobileBreakpoint);
    const hasMobileVideo = getFileType(assetMobile?.filetype) === 'video';
    const hasDesktopVideo = getFileType(assetDesktop?.filetype) === 'video';
    const isDesktop = useBreakpoint(mobileBreakpoint);

    useEffect(() => {
      if (typeof videoRef !== 'function') {
        videoRef?.current?.load();
      }
    }, [isDesktop, videoRef]);

    return (
      <FillVideo
        disableRemotePlayback
        muted
        playsInline
        poster={poster?.url}
        ref={videoRef}
        {...rest}
        style={{ ...rest.style, objectFit: layout }}
        {...(isLow && { autoPlay: false })}
      >
        {hasDesktopVideo && (
          <source
            {...(hasMobileVideo && { media: `(min-width: ${breakpoints[mobileBreakpoint]}px)` })}
            src={assetDesktop?.url}
            type={assetDesktop?.filetype}
          />
        )}

        {hasMobileVideo && (
          <source
            {...(hasDesktopVideo && { media: `(max-width: ${breakpoints[mobileBreakpoint]}px)` })}
            src={assetMobile?.url}
            type={assetMobile?.filetype}
          />
        )}
      </FillVideo>
    );
  }
);

/**
 * `VideoCommon` display name.
 */

VideoCommon.displayName = 'VideoCommon';

/**
 * `VideoOnEntry` component.
 */

const VideoOnEntry = forwardRef<HTMLVideoElement, Props>((props, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const isInView = useInView(videoRef);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (isInView && videoRef.current.currentTime === 0) {
      videoRef.current.play();
    }

    if (!isInView) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [isInView, videoRef]);

  return <VideoCommon ref={mergeRefs(videoRef, ref)} {...props} />;
});

/**
 * `VideoOnEntry` display name.
 */

VideoOnEntry.displayName = 'VideoOnEntry';

/**
 * Export `Video` component.
 */

export const Video = forwardRef<HTMLVideoElement, Props>((props, ref) => {
  const supportsHevcAlpha = useSupportsHevcAlpha();
  const { isLow } = useBattery();

  if (supportsHevcAlpha === undefined || isLow === undefined) {
    return null;
  }

  if (!props.loop && !isLow) {
    return <VideoOnEntry ref={ref} {...props} />;
  }

  return <VideoCommon autoPlay loop ref={ref} {...props} />;
});

/**
 * `Video` display name.
 */

Video.displayName = 'Video';

/**
 * Module dependencies.
 */

import { Collapse } from 'src/components/core/collapse';
import { ComponentPropsWithoutRef, useState } from 'react';
import { FaqsSectionFragment } from 'src/api/entities/sections/faqs/types';
import { RichText } from 'src/components/rich-text';
import { Section } from 'src/components/core/layout/section';
import { SectionFootnote } from 'src/components/sections/shared/footnote';
import { bulletStyles } from 'src/components/core/layout/headings';
import { fadeInTopAnimation } from 'src/core/constants/motion';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * Export `FaqsSectionProps` type.
 */

export type FaqsSectionProps = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & FaqsSectionFragment;

/**
 * `CollapseWrapper` styled component.
 */

const CollapseWrapper = styled.div`
  display: grid;
  grid-row-gap: 8px;
  margin: 0 var(--gutter-cards);
  padding-top: 8px;

  ${media.min.ms`
    grid-row-gap: 13px;
    padding-top: 16px;
  `}
`;

/**
 * `Answer` styled component.
 */

const Answer = styled(RichText)`
  ${textStyles.paragraph2}
  ${bulletStyles}

  ul {
    margin-left: 16px;
  }

  font-weight: 400;
`;

/**
 * `Faq` component.
 */

function Faq({ answer, question }: FaqsSectionFragment['items'][0]) {
  const [active, setActive] = useState(false);

  return (
    <motion.div {...fadeInTopAnimation(0.15)}>
      <Collapse
        contentStyle={{ paddingTop: 0 }}
        isExpanded={active}
        onClick={() => setActive(!active)}
        title={question}
      >
        <Answer>{answer}</Answer>
      </Collapse>
    </motion.div>
  );
}

/**
 * Export `FaqsSection` component.
 */

export function FaqsSection({ footnote, items, ...rest }: FaqsSectionProps) {
  return (
    <Section {...rest}>
      <CollapseWrapper>
        {items.map(item => (
          <Faq key={item?.id} {...item} />
        ))}
      </CollapseWrapper>

      {footnote && <SectionFootnote>{footnote}</SectionFootnote>}
    </Section>
  );
}

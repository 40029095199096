/**
 * Module dependencies.
 */

import { CSSProperties, ReactNode } from 'react';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import chevronSvg from 'src/assets/svgs/32/chevron-down.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  ariaLabelledBy?: string;
  children: ReactNode;
  className?: string;
  contentStyle?: CSSProperties;
  isExpanded?: boolean;
  onClick?: () => void;
  title?: string;
};

/**
 * `Heading` styled component.
 */

const Heading = styled.button`
  align-items: center;
  background-color: inherit;
  border: none;
  border-radius: var(--border-radius);
  color: var(--collapse-title-color);
  cursor: pointer;
  display: grid;
  font-weight: 700;
  gap: 24px;
  grid-template-columns: 1fr 32px;
  outline: none;
  padding: 24px;
  position: relative;
  text-align: inherit;
  transition: color var(--transition-default);

  ${media.min.ms`
    padding: 24px 32px;
  `}

  :focus,
  :hover {
    ${media.min.ms`
      --collapse-icon-color: var(--collapse-heading-active-color);
      --collapse-title-color: var(--collapse-heading-active-color);
    `}

    outline: none;
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows var(--transition-default);
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  opacity: 0;
  overflow: hidden;
  padding: 8px 24px;
  transform: translateY(-100%);
  transition: var(--transition-default);
  transition-property: opacity, transform;

  ${media.min.ms`
    padding: 8px 32px 16px;
  `}
`;

/**
 * `IconWrapper` styled component.
 */

const IconWrapper = styled.div`
  display: flex;
  position: relative;
  transition: var(--transition-default);
  transition-property: color, transform;
`;

/**
 * `ChevronIcon` styled component.
 */

const ChevronIcon = styled(Svg).attrs({
  icon: chevronSvg,
  size: '32px'
})`
  color: var(--collapse-icon-color);
  transition: color var(--transition-default);
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --collapse-background-color: var(--color-neutral0);
  --collapse-heading-active-color: var(--color-primary);
  --collapse-icon-color: var(--color-neutral40);
  --collapse-text-color: var(--color-neutral95);
  --collapse-title-color: var(--color-neutral80);

  [data-theme='dark'] & {
    --collapse-background-color: var(--color-neutral95);
    --collapse-text-color: var(--color-neutral0);
    --collapse-title-color: var(--color-neutral10);
  }

  background-color: var(--collapse-background-color);
  border-radius: var(--border-radius);
  color: var(--collapse-text-color);
  display: grid;
  overflow: hidden;

  &[data-expanded='true'] {
    --collapse-title-color: var(--color-primary);

    ${IconWrapper} {
      transform: rotate(-180deg);
    }

    ${Grid} {
      grid-template-rows: 1fr;
    }

    ${Content} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

/**
 * Export `Collapse` styled component.
 */

export function Collapse({ children, className, contentStyle, isExpanded, title, ...rest }: Props) {
  return (
    <Wrapper className={className} data-expanded={isExpanded}>
      <Heading aria-expanded={isExpanded} {...rest}>
        <Text block variant={'paragraph1'}>
          {title}
        </Text>

        <IconWrapper>
          <ChevronIcon />
        </IconWrapper>
      </Heading>

      <Grid>
        <div style={{ minHeight: 0 }}>
          <Content style={contentStyle}>{children}</Content>
        </div>
      </Grid>
    </Wrapper>
  );
}

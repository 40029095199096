/**
 * Module dependencies.
 */

import { CSSProperties, ComponentPropsWithoutRef, useRef, useState } from 'react';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { useIsomorphicLayoutEffect } from 'framer-motion';
import checkIcon from 'src/assets/svgs/20/circle-check.svg';
import circleIcon from 'src/assets/svgs/20/circle.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ComponentPropsWithoutRef<'div'> & {
  childIndex?: number;
  childNodes?: { href: string; label: string }[];
  isNumeric?: boolean;
  selfIndex: number;
  siblingNodes: { completed?: boolean; href: string; label: string }[];
  title?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --scroll-spy-background-1: var(--color-neutral0);
  --scroll-spy-background-2: var(--color-neutral05);
  --scroll-spy-color: var(--color-neutral50);

  [data-theme='dark'] &,
  &[data-theme='dark'] {
    --scroll-spy-background-1: var(--color-neutral95);
    --scroll-spy-background-2: var(--color-neutral90);
    --scroll-spy-color: var(--color-neutral40);
  }

  background-color: var(--scroll-spy-background-1);
  border-radius: var(--border-radius);
  color: var(--scroll-spy-color);
  padding: 16px 0;
  transition: var(--transition-default);
  transition-property: background-color, color;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'div', fontWeight: 400, variant: 'subtitle2' })`
  color: var(--color-text);
  padding: 12px 24px;
  transition: color var(--transition-fast);
`;

/**
 * `Sibling` styled component.
 */

const Sibling = styled.li`
  > a {
    column-gap: 12px;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content 1fr;
    padding: 12px 24px;
    transition: background-color var(--transition-default);

    :hover {
      background-color: var(--scroll-spy-background-2);
    }
  }

  &[data-current='true'] > a {
    color: var(--color-primary);
  }
`;

/**
 * `SiblingIcon` styled component.
 */

const SiblingIcon = styled(Svg)`
  color: var(--color-primary);
  margin-top: 0.2em;

  *:not([data-completed='true']):not([data-current='true']) > a > & {
    opacity: 0.125;
  }
`;

/**
 * `ChildrenList` styled component.
 */

const ChildrenList = styled.ul`
  padding: 16px 0 24px 56px;
  position: relative;

  ::before {
    background-color: var(--color-primary);
    bottom: var(--scroll-spy-current-bottom);
    content: '';
    left: 56px;
    position: absolute;
    top: var(--scroll-spy-current-top);
    transition: var(--transition-default);
    transition-property: top, bottom;
    width: 2px;
  }
`;

/**
 * `ChildNode` styled component.
 */

const ChildNode = styled.li`
  cursor: pointer;
  padding: 8px 32px 8px 12px;
  transition: var(--transition-fast);
  transition-property: background-color, color;

  :hover {
    background-color: var(--scroll-spy-background-2);
  }

  &[data-current='true'] {
    color: var(--color-text);
  }
`;

/**
 * Export `ScrollSpy` component.
 */

export const ScrollSpy = (props: Props) => {
  const { childIndex, childNodes, isNumeric, selfIndex, siblingNodes, title, ...rest } = props;
  const childrenListRef = useRef<HTMLUListElement>(null);
  const [[currentTop, currentBottom], setCurrentLimits] = useState<[string?, string?]>([]);

  useIsomorphicLayoutEffect(() => {
    if (!childIndex) {
      return;
    }

    const totalHeight = childrenListRef.current?.offsetHeight ?? 0;
    const top = (childrenListRef.current?.children[childIndex] as HTMLElement)?.offsetTop ?? 0;
    const bottom =
      totalHeight - ((childrenListRef.current?.children[childIndex + 1] as HTMLElement)?.offsetTop ?? totalHeight - 24);

    setCurrentLimits([`${top + 10}px`, `${bottom + 10}px`]);
  }, [childIndex, childNodes]);

  return (
    <Wrapper {...rest}>
      {!!title?.length && <Title>{title}</Title>}

      <ul>
        {siblingNodes.map((siblingNode, index) => (
          <Sibling data-completed={siblingNode.completed} data-current={index === selfIndex} key={index}>
            <RouterLink href={siblingNode.href}>
              {isNumeric ? (
                <Text fontWeight={400} style={{ width: 20 }} variant={'paragraph1'}>
                  {`${index + 1}.`}
                </Text>
              ) : (
                <SiblingIcon
                  color={'var(--color-primary)'}
                  icon={siblingNode.completed ? checkIcon : circleIcon}
                  size={'20px'}
                />
              )}

              <Text fontWeight={400} variant={'paragraph1'}>
                {siblingNode.label}
              </Text>
            </RouterLink>

            {index === selfIndex && childNodes && childNodes.length > 0 && (
              <ChildrenList
                ref={childrenListRef}
                style={
                  {
                    '--scroll-spy-current-bottom': currentBottom,
                    '--scroll-spy-current-top': currentTop
                  } as CSSProperties
                }
              >
                {childNodes.map((childNode, index) => (
                  <ChildNode data-current={index === childIndex} key={index}>
                    <RouterLink href={childNode.href}>
                      <Text fontWeight={400} variant={'paragraph1'}>
                        {childNode.label}
                      </Text>
                    </RouterLink>
                  </ChildNode>
                ))}
              </ChildrenList>
            )}
          </Sibling>
        ))}
      </ul>
    </Wrapper>
  );
};

/**
 * Module dependencies.
 */

import { PropsWithChildren, forwardRef } from 'react';
import { RichTextClean } from './clean';
import { linkAnimatedStyles } from 'src/components/core/links';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = PropsWithChildren & {
  className?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --richtext-element-top-space: 24px;
  --richtext-element-bottom-space: 16px;
  --richtext-element-bottom-spaceL: 24px;

  > * {
    padding-bottom: var(--richtext-element-bottom-space);
  }

  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-top: var(--richtext-element-top-space) !important;
  }

  h3 {
    ${textStyles.subtitle1}
  }

  h4 {
    ${textStyles.subtitle2}
  }

  h5 {
    ${textStyles.paragraph1}
  }

  h6 {
    ${textStyles.paragraph2}
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding-bottom: var(--richtext-element-bottom-spaceL);
    padding-left: 32px;
  }

  a {
    ${linkAnimatedStyles}

    color: var(--color-primary);
  }

  video,
  img {
    max-width: 100%;
  }

  .highlight {
    color: var(--color-primary);
  }

  .button {
    align-items: center;
    color: var(--color-primary);
    display: inline-flex;
    font-size: 20px;
    line-height: 26px;

    ::before {
      content: url('/images/arrow-right.svg');
      display: inline-block;
      height: 20px;
      margin-right: 8px;
    }
  }

  blockquote {
    padding-left: 16px;
    position: relative;

    &::before {
      background-color: var(--color-primary);
      border-radius: var(--border-radius);
      content: '';
      display: block;
      inset: 0 auto 0 0;
      position: absolute;
      width: 4px;
    }
  }
`;

/**
 * Export `RichText` component.
 */

export const RichText = forwardRef<HTMLDivElement, Props>(({ children, ...rest }, ref) => (
  <RichTextClean element={Wrapper} ref={ref} {...rest}>
    {children}
  </RichTextClean>
));

/**
 * Set display name.
 */

RichText.displayName = 'RichText';
